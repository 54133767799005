/*-----------------------------------*\
  #CONTACT PAGE STYLES
\*-----------------------------------*/

/* Hero Section */
.heros .hero-contents .h1,
.heros .hero-contents .hero-text {
  color: var(--white); /* Ensures white text for the hero section */
  text-align: center;
}

/* Contact Information Section */
.contact-infos {
  text-align: center;
  margin-top: 40px;
  padding-block: var(--section-padding);
}

.contact-infos .section-title {
  color: var(--eerie-black-1);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-2);
  font-weight: var(--fw-600);
  margin-bottom: 20px;
}

.contact-detailss {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  color: var(--gray-web);
}

.contact-detailss li {
  display: flex;
  align-items: center;
  font-size: var(--fs-5);
  gap: 15px;
  color: var(--eerie-black-1);
}

.contact-detailss li ion-icon {
  color: var(--kappel);
  font-size: 24px;
}

/* Contact Form Section */
.contact-form {
  margin-top: 40px;
  padding-block: var(--section-padding);
  background-color: var(--isabelline);
  border-radius: var(--radius-10);
}

.contact-form .section-title {
  color: var(--eerie-black-1);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-2);
  font-weight: var(--fw-600);
  text-align: center;
  margin-bottom: 30px;
}

.form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: var(--fs-5);
  color: var(--gray-web);
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  font-size: var(--fs-5);
  border: 1px solid var(--light-gray);
  border-radius: var(--radius-5);
  transition: var(--transition-1);
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--kappel);
}

.form-group textarea {
  resize: vertical;
  height: 120px;
}

.btns {
  background-color: var(--kappel);
  color: var(--white);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-4);
  display: inline-block;
  padding: 12px 25px;
  text-align: center;
  border-radius: var(--radius-5);
  transition: var(--transition-2);
}

.btn:hover {
  background-color: var(--radical-red);
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .contact-detailss {
    flex-direction: row;
    justify-content: space-between;
  }

  .form {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .form-group {
    width: calc(50% - 15px);
  }

  .form-group.full-width {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .contact-info,
  .contact-form {
    padding-inline: var(--section-padding);
  }
}
