.thank-you-section {
    padding: 100px 0;
    text-align: center;
    background-color: var(--platinum);
    border-radius: var(--radius-5);
    box-shadow: var(--shadow-2);
  }
  
  .thank-you-content {
    max-width: 600px;
    margin: auto;
  }
  
  .thank-you-text {
    color: var(--eerie-black-1);
    font-size: var(--fs-4);
    margin: 20px 0;
  }
  
  .thank-you-section .btn {
    margin-top: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    background-color: var(--kappel);
    color: var(--white);
    border-radius: var(--radius-5);
    text-decoration: none;
    font-size: var(--fs-4);
  }
  
  .thank-you-section .btn:hover {
    background-image: var(--gradient);
  }
  