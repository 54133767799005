.lead-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    margin: auto;
    padding: 20px;
    background-color: var(--platinum);
    border-radius: var(--radius-5);
    box-shadow: var(--shadow-2);
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .input-group label {
    font-size: var(--fs-6);
    font-weight: var(--fw-500);
    color: var(--eerie-black-1);
  }
  
  .input-group input {
    padding: 10px;
    border: 1px solid var(--gray-x-11);
    border-radius: var(--radius-3);
    font-size: var(--fs-5);
  }
  
  .input-group input:focus {
    border-color: var(--kappel);
    outline: none;
  }
  
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background-color: var(--kappel);
    color: var(--white);
    border-radius: var(--radius-5);
    font-size: var(--fs-4);
    transition: var(--transition-1);
  }
  
  .btn:hover {
    background-image: var(--gradient);
  }
  